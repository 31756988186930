<template>
  <tr>
    <td>{{ symbol }}</td>
    <td>{{ getFormatedDate }}</td>
    <td style="text-align: right">{{ dividend }}</td>
    <td style="text-align: right">{{ currency }}</td>
    <td>{{ description }}</td>
    <td>{{ imported }}</td>
  </tr>
</template>

<script setup>
//VUE core
//import { defineProps } from 'vue';
import { defineProps, computed } from 'vue';
import appUtils from '../utils/appUtils';

//const props = defineProps(['symbol', 'description']);
const props = defineProps([
  'symbol',
  'description',
  'currency',
  'date',
  'dividend',
  'imported',
]);

const getFormatedDate = computed(function () {
  return appUtils.getFormatedDate(props.date);
});
</script>
